<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px">
      <div class="text-center mt-5 mb-5">
        <b-img :src="logo" width="200" :alt="brand" />
      </div>
      <!-- <div class="d-flex justify-content-center mb-4 navbar-light">
        <component
          :is="linkComponent"
          :to="routeTo(routeLogo || routes.home)"
          class="navbar-brand m-0"
          v-text="brand"
        />
      </div> -->
      <card
        :title="$t('authMsgs.forgotPass')"
        :subtitle="$t('authMsgs.forgotPassSubtitle')"
        header-class="text-center"
        class="navbar-shadow"
      >
        <slot />

        <b-alert variant="soft-success" :show="success" class="d-flex flex-wrap">
          <div class="mr-8pt">
            <i class="material-icons">check_circle</i>
          </div>
          <div class="flex" style="min-width: 180px">
            <small class="text-black-100" v-text="$t('authMsgs.forgotPassSuccessMsg')" />
          </div>
        </b-alert>

        <b-form @submit.prevent="onSubmit">
          <b-form-group
            :label="`${$t('authMsgs.emailAddr')}:`"
            :description="$t('authMsgs.forgotPassInstrMsg')"
            label-for="email"
            label-class="form-label"
          >
            <fmv-input-group-merge
              id="email"
              v-model="form.email"
              :invalid-feedback="invalidFeedback"
              :state="state"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              required
              prepend
              :disabled="isLoading || success"
            >
              <span class="fas fa-envelope" />
            </fmv-input-group-merge>
            <b-form-invalid-feedback v-text="invalidFeedback" :state="state" />
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot reset password for the given email. Please try again.
          </div>

          <div class="form-group">
            <b-btn type="submit" variant="primary" block :disabled="isLoading || success" class="btn-normal">
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>{{ $t('authMsgs.resetPassword') }}</span>
            </b-btn>
          </div>

          <div class="text-center">
            {{ $t('authMsgs.rememberPass') }}?
            <component
              :is="linkComponent"
              :to="routeTo(routes.login)"
              class="text-black-70"
              style="text-decoration: underline"
              v-text="$t('authMsgs.login')"
            />
          </div>
        </b-form>
      </card>
    </div>
  </div>
</template>


<script>
import Card from '@/components/Ui/Card.vue';

import Page from '@/components/Page';

import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import { FmvInputGroupMerge } from 'fmv-input-group-merge';

import logo from '@/assets/images/logos/transition_enroll_tp.png';

export default {
  components: {
    Card,
    FmvAvatar,
    FmvInputGroupMerge,
  },
  extends: Page,
  layout: 'blank',
  props: {
    brand: { type: String, default: () => 'Transition Enroll' },
    logo: { type: String, default: () => logo },
    routeLogo: { type: [String, Object] },
    routeLogin: { type: [String, Object] },
    errors: { type: Object, default: () => {} },
    linkComponent: { type: [String, Object], default: () => 'b-link' },
  },

  data() {
    return {
      title: 'Forgot Password',
      form: {
        email: null,
      },
      success: false,
      error: false,
      invalidFeedback: null,
      state: null,
      isLoading: false,
    };
  },

  computed: {
    errorState() {
      return this.error ? false : undefined;
    },
  },

  mounted() {
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
  },

  watch: {},

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('auth', ['resetPassword']),

    getErrors(key) {
      if (this.errors && this.errors[key] !== undefined) {
        return this.errors[key];
      }
    },
    async onSubmit() {
      this.isLoading = true;

      try {
        await this.resetPassword({ email: this.form.email });
        this.success = true;
      } catch (_e) {
        this.error = true;
      }

      this.isLoading = false;
    },
  },
};
</script>
