<template>
  <div class="d-flex align-items-center" style="min-height: 100vh">
    <div class="col-sm-8 col-md-6 col-lg-6 mx-auto" style="min-width: 300px">
      <div class="row mt-5 mb-5" :class="'d-flex justify-content-center'">
        <div class="d-flex justify-content-center col-6 align-items-center">
          <div>
            <b-img :src="get(entity, 'logo_url')" width="150" :alt="get(entity, 'name')" />
          </div>
        </div>
      </div>

      <card
        title="Request for new Instructor"
        :subtitle="`Fill the form given below to get yourself approved as an instructor for ${get(entity, 'name')}`"
        header-class="text-center"
        class="navbar-shadow"
      >
        <slot />

        <b-form @submit.prevent="onSubmit">
          <page-separator title="Your Information" />

          <b-form-group :label="$t('Photo')" label-for="avatar" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center">
              <user-avatar
                slot="aside"
                size="lg"
                :isLoading="isUploading.avatar"
                :user="user"
                url-field="avatarUrl"
                first-name-field="firstName"
                last-name-field="lastName"
              >
              </user-avatar>

              <image-uploader
                @image="e => setImage(e, 'avatar')"
                :isUploading="isUploading.avatar"
                :isRequired="!$v.user.avatarUrl.required"
                :placeholder="$t('Choose photo')"
              />
            </b-media>
          </b-form-group>

          <div class="row">
            <b-form-group
              :label="$t('userMsgs.firstName')"
              label-for="firstname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="firstname"
                :placeholder="$t('userMsgs.firstName')"
                v-model.trim="user.firstName"
                :state="!$v.user.firstName.required && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              :label="$t('userMsgs.lastName')"
              label-for="lastname"
              label-class="form-label"
              class="col-md-6"
            >
              <b-form-input
                id="lastname"
                :placeholder="$t('userMsgs.lastName')"
                v-model.trim="user.lastName"
                :state="!$v.user.lastName.required && $v.user.$dirty ? false : null"
              />
              <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
            </b-form-group>
          </div>

          <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label">
            <b-form-input
              id="email"
              :placeholder="$t('authMsgs.emailAddr')"
              type="email"
              v-model.trim="user.email"
              :state="(!$v.user.email.required || !$v.user.email.email) && $v.user.$dirty ? false : null"
            />
            <b-form-invalid-feedback v-if="!$v.user.email.email">
              Invalid email. Please make sure that it's in correct format i.e.
              test@example.com.</b-form-invalid-feedback
            >
            <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group :label="$t('employerMsgs.phone')" label-for="phone" label-class="form-label">
            <b-form-input
              id="phone"
              placeholder="(888) 689 - 1235"
              v-model="user.phone"
              v-mask="'(###) ### - ####'"
              :state="
                (!$v.user.phone.required || !$v.user.phone.isCorrectFormat) && $v.user.phone.$dirty ? false : null
              "
            />
            <b-form-invalid-feedback v-if="!$v.user.phone.required"> This field is required. </b-form-invalid-feedback>
            <b-form-invalid-feedback v-else>
              Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Bio" label-for="bio" label-class="form-label">
            <editor
              :init="{
                height: 400,
                menubar: 'edit view insert format tools table help',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
              }"
              v-model="user.bio"
              placeholder="Enter bio"
              :state="!$v.user.bio.required && $v.user.bio.$dirty ? false : null"
            />
            <b-form-invalid-feedback :state="!$v.user.bio.required && $v.user.bio.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <page-separator title="Attachments" />

          <b-form-group label="Attachment 1" label-for="image" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center" v-if="!user.attachment_1">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isAttach1Loading">...</span>
                <b-img
                  :src="user.attachment_1"
                  class="img-fluid"
                  width="40"
                  alt="Attach1"
                  v-else-if="user.attachment_1"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <b-form-file
                id="attach1"
                placeholder="Select Attachment"
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedUploadFile.attachment_1"
                @input="uploadImage('attachment_1')"
                :disabled="isAttach1Loading"
                accept="image/*"
              />
            </b-media>
            <span v-else>
              <div class="row">
                <div class="col-md-10">
                  <a
                    href="#"
                    @click.prevent="
                      downloadGivenFile({
                        fileUrl: user.attachment_1,
                        removeTimestamp: true
                      })
                    "
                    v-b-popover.hover.top="'Download'"
                    ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(user.attachment_1, true) }}
                  </a>
                </div>
                <div class="text-right col-md-2">
                  <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_1')"
                    ><i class="fas fa-times-circle"></i> Remove
                  </a>
                </div>
              </div>
            </span>
          </b-form-group>
          <b-form-group label="Attachment 2" label-for="image" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center" v-if="!user.attachment_2">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isAttach2Loading">...</span>
                <b-img
                  :src="user.attachment_2"
                  class="img-fluid"
                  width="40"
                  alt="Image"
                  v-else-if="user.attachment_2"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <b-form-file
                id="attach2"
                placeholder="Select Attachment "
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedUploadFile.attachment_2"
                @input="uploadImage('attachment_2')"
                :disabled="isAttach2Loading"
                accept="image/*"
              />
            </b-media>
            <span v-else>
              <div class="row">
                <div class="col-md-10">
                  <a
                    href="#"
                    @click.prevent="
                      downloadGivenFile({
                        fileUrl: user.attachment_2,
                        removeTimestamp: true
                      })
                    "
                    v-b-popover.hover.top="'Download'"
                    ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(user.attachment_2, true) }}
                  </a>
                </div>
                <div class="text-right col-md-2">
                  <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_2')"
                    ><i class="fas fa-times-circle"></i> Remove
                  </a>
                </div>
              </div>
            </span>
          </b-form-group>
          <b-form-group label="Attachment 3" label-for="image" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center" v-if="!user.attachment_3">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isAttach3Loading">...</span>
                <b-img
                  :src="user.attachment_3"
                  class="img-fluid"
                  width="40"
                  alt="Image"
                  v-else-if="user.attachment_3"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <b-form-file
                id="attach3"
                placeholder="Select Attachment"
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedUploadFile.attachment_3"
                @input="uploadImage('attachment_3')"
                :disabled="isAttach3Loading"
                accept="image/*"
              />
            </b-media>
            <span v-else>
              <div class="row">
                <div class="col-md-10">
                  <a
                    href="#"
                    @click.prevent="
                      downloadGivenFile({
                        fileUrl: user.attachment_3,
                        removeTimestamp: true
                      })
                    "
                    v-b-popover.hover.top="'Download'"
                    ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(user.attachment_3, true) }}
                  </a>
                </div>
                <div class="text-right col-md-2">
                  <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_3')"
                    ><i class="fas fa-times-circle"></i> Remove
                  </a>
                </div>
              </div>
            </span>
          </b-form-group>
          <b-form-group label="Attachment 4" label-for="image" label-class="form-label" class="row-align-items-center">
            <b-media class="align-items-center" vertical-align="center" v-if="!user.attachment_4">
              <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
                <span v-if="isAttach4Loading">...</span>
                <b-img
                  :src="user.attachment_4"
                  class="img-fluid"
                  width="40"
                  alt="Image"
                  v-else-if="user.attachment_4"
                />
                <i class="fas fa-university fa-lg" v-else></i>
              </fmv-avatar>

              <b-form-file
                id="attach4"
                placeholder="Select Attachment"
                :browse-text="$t('generalMsgs.browse')"
                v-model="selectedUploadFile.attachment_4"
                @input="uploadImage('attachment_4')"
                :disabled="isAttach4Loading"
                accept="image/*"
              />
            </b-media>
            <span v-else>
              <div class="row">
                <div class="col-md-10">
                  <a
                    href="#"
                    @click.prevent="
                      downloadGivenFile({
                        fileUrl: user.attachment_4,
                        removeTimestamp: true
                      })
                    "
                    v-b-popover.hover.top="'Download'"
                    ><i class="fas fa-file text-primary"></i> {{ parseFileNameFromUrl(user.attachment_4, true) }}
                  </a>
                </div>
                <div class="text-right col-md-2">
                  <a href="#" class="text-danger" @click.prevent="removeAttach('attachment_4')"
                    ><i class="fas fa-times-circle"></i> Remove
                  </a>
                </div>
              </div>
            </span>
          </b-form-group>

          <div v-if="error" class="alert alert-danger mt-3" role="alert">
            Cannot create an account with the given credentials. Please contact administrator.
          </div>

          <div class="form-group">
            <b-btn type="submit" variant="primary" block :disabled="isFormLoading" class="btn-normal">
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Register</span>
            </b-btn>
          </div>
        </b-form>
      </card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';
import Card from '@/components/Ui/Card.vue';
import { mapActions } from 'vuex';
import Page from '@/components/Page';
import { titleize, parseFileNameFromUrl } from '@/common/utils';
import { email, required } from 'vuelidate/lib/validators';
import { PHONE_FORMAT_REGEX } from '@/common/constants';
import Editor from '@tinymce/tinymce-vue';
import UserAvatar from '../../components/User/UserAvatar.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  name: 'InstructorSignup',
  components: { Card, UserAvatar, Editor, FmvAvatar, PageSeparator, ImageUploader },
  extends: Page,
  layout: 'blank',

  data() {
    return {
      title: 'Request for new Instructor',
      user: {
        avatarUrl: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        bio: '',
        attachment_1: '',
        attachment_2: '',
        attachment_3: '',
        attachment_4: ''
      },
      entity: {},
      errorStates: { phone: null },

      error: false,
      isLoading: false,

      isAttach1Loading: false,
      isAttach2Loading: false,
      isAttach3Loading: false,
      isAttach4Loading: false,

      isUploading: {
        avatar: false,
        attachment_1: false,
        attachment_2: false,
        attachment_3: false,
        attachment_4: false
      },
      selectedUploadFile: {
        avatar: null,
        attachment_1: null,
        attachment_2: null,
        attachment_3: null,
        attachment_4: null
      },
      uploadPercent: { avatar: 0, attachment_1: 0, attachment_2: 0, attachment_3: 0, attachment_4: 0 },
      uploadCancelTokenSource: {
        avatar: null,
        attachment_1: null,
        attachment_2: null,
        attachment_3: null,
        attachment_4: null
      },

      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY
    };
  },

  validations() {
    return {
      user: {
        avatarUrl: { required },
        firstName: { required },
        lastName: { required },
        email: { required, email },
        phone: {
          required,
          isCorrectFormat(value) {
            return PHONE_FORMAT_REGEX.test(value);
          }
        },
        bio: { required }
      }
    };
  },

  computed: {
    errorState() {
      return this.error ? false : undefined;
    },

    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    }
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    ...mapActions('instructor', ['signupInstructor', 'getInstructorImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('school', ['getSchoolByInstructorId']),
    ...mapActions('fileDownload', ['downloadFile']),
    get,
    setImage(file, image) {
      this.selectedUploadFile[image] = file;
      this.uploadImage(image);
    },
    parseFileNameFromUrl,
    removeAttach(fileType) {
      this.selectedUploadFile[fileType] = null;
      this.user[fileType] = null;
    },
    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left'
      });

      await this.downloadFile({ fileUrl, removeTimestamp });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left'
      });
    },
    async getEntity() {
      try {
        const res = await this.getSchoolByInstructorId({
          instructor_id: this.$route.query.inviteId
        });
        this.entity = res.data;
      } catch (e) {
        // TODO: error
      }
    },

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const data = {
            email: this.user.email,
            first_name: titleize(this.user.firstName),
            last_name: titleize(this.user.lastName),
            bio: this.user.bio,
            phone: this.user.phone,
            photo: this.user.avatarUrl,
            attachment_1: this.user.attachment_1,
            attachment_2: this.user.attachment_2,
            attachment_3: this.user.attachment_3,
            attachment_4: this.user.attachment_4,
            instructor_id: this.$route.query.inviteId
          };

          await this.signupInstructor(data);
          this.$router.push({
            name: 'verify-instructor-signup',
            params: {
              submission: 'success'
            }
          });
        } catch (_e) {
          this.error = true;
          this.$router.push({
            name: 'verify-instructor-signup',
            params: {
              submission: 'failed'
            }
          });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },

    async uploadImage(fileType, uploadType = 'images') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;
      this.isAttach1Loading = fileType === 'attachment_1';
      this.isAttach2Loading = fileType === 'attachment_2';
      this.isAttach3Loading = fileType === 'attachment_3';
      this.isAttach4Loading = fileType === 'attachment_4';

      try {
        const urlResp = await this.getInstructorImageUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type,
          upload_type: uploadType
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (fileType === 'avatar') {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'attachment_1') {
          this.user.attachment_1 = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'attachment_2') {
          this.user.attachment_2 = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'attachment_3') {
          this.user.attachment_3 = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'attachment_4') {
          this.user.attachment_4 = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
      if (fileType === 'attachment_1') {
        this.isAttach1Loading = false;
      } else if (fileType === 'attachment_2') {
        this.isAttach2Loading = false;
      } else if (fileType === 'attachment_3') {
        this.isAttach3Loading = false;
      } else if (fileType === 'attachment_4') {
        this.isAttach4Loading = false;
      }
    }
  },

  mounted() {
    if (this.$route.query.inviteId) {
      this.getEntity();
    }
    if (!this.isLoggedIn) {
      this.setSettings({ layout: { layout: 'blank' } });
    }
  }
};
</script>
